// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import stylesheet from "~/tailwind.css";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import { useEffect } from "react";
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}, {
  rel: "icon",
  href: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 16 16'><text x='0' y='14'>🧪</text></svg>"
}];
export const meta = ({
  matches,
  location,
  params,
  data
}) => {
  return [{
    charSet: "utf-8",
    title: "Cloudflare Hyperdrive Demo",
    description: "A demo app that uses Cloudflare Hyperdrive to dramatically speed up database access."
  }];
};
export default function App() {
  _s();
  useEffect(() => {});
  return <html lang="en" className="dark">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      {/* <body className="bg-slate-200"> */}
      {/* <body className="bg-gradient-to-b from-[#ffffff] dark:from-[#000000] from-15% to-[#ECF4FF] dark:to-[#1D1D1D] to-80%"> */}
      <body>
        <div className="bg-sky-100 dark:bg-zinc-900 overflow-auto">
          <div className="bg-gradient-to-b from-white dark:from-black to-sky-100 dark:to-zinc-900 h-screen">
            <main className="h-screen text-black dark:text-white p-8">
              <Outlet />
              <ScrollRestoration />
              <Scripts />
              <LiveReload />
            </main>
          </div>
        </div>
      </body>
    </html>;
}
_s(App, "OD7bBpZva5O2jO+Puf00hKivP7c=");
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;